.tab {
  padding: 1em;
  float: left;
  margin-right: auto;
}

.tab__section {
  display: flex;
  flex-direction: column;
  background: linear-gradient(113.35deg, #ffd060 -7.82%, #8d5d14 136.83%);
  border: none;
  outline: none !important;
  border-radius: 12px;
  /* z-index: -10; */
  @apply w-full mb-6;
}

/* Style the tab content title */
.tab__title {
  font-weight: 500;
  @apply text-base sm:text-lg pr-2;
  color: #000000;
  text-align: center !important;
}

.displayBorder {
  background-color: #16171b;
  overflow: auto;
  transition: max-height 0.3s ease;
  border-radius: 0px 0px 12px 12px !important;
  border: 1px solid #d4af58;
  border-top: none;
  border-bottom: none;
}
@media screen and (max-height: 700px) {
  .tab {
    padding: 0.8em;
    float: left;
    margin-right: auto;
  }

  .tab__section {
    display: flex;
    flex-direction: column;
    background: linear-gradient(113.35deg, #ffd060 -7.82%, #8d5d14 136.83%);
    border: none;
    outline: none !important;
    border-radius: 12px;
    /* z-index: -10; */
    @apply w-full mb-4;
  }

  /* Style the tab content title */
  .tab__title {
    font-weight: 500;
    @apply text-base sm:text-lg pr-2;
    color: #000000;
  }

  .displayBorder {
    background-color: #16171b;
    overflow: auto;
    transition: max-height 0.3s ease;
    border-radius: 0px 0px 12px 12px !important;
    border: 1px solid #d4af58;
    border-top: none;
    border-bottom: none;
  }
}
