.img {
  filter: drop-shadow(0px 26.6667px 21.3333px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 11.1407px 8.91256px rgba(0, 0, 0, 0.0503198))
    drop-shadow(0px 5.95635px 4.76508px rgba(0, 0, 0, 0.0417275))
    drop-shadow(0px 3.33908px 2.67126px rgba(0, 0, 0, 0.035))
    drop-shadow(0px 1.77336px 1.41869px rgba(0, 0, 0, 0.0282725))
    drop-shadow(0px 0.737935px 0.590348px rgba(0, 0, 0, 0.0196802));
}

.zindex{
  z-index:2;

}
.zindexguy{
  z-index:1;
}
.zindexminus{
  z-index:-1;

}

@media screen and (min-width: 320px) and (max-width: 400px) and (min-height: 700px) {
  .vertical-break-team{
    height: 15rem;
    top: 16rem;
  }
  .vertical-break-teammember{
    top: 15rem;
  }
  .vertical-break-ellipse{
    top: 7rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) and (min-height: 500px) and (max-height: 570px) {
  .vertical-break-team{
    height: 10rem;
    top: 12rem;
  }
  .vertical-break-teammember{
    top: 10rem;
  }
  .vertical-break-ellipse{
    top: 4rem;
  }
}