.container {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  /* z-index: -10; */
  color: white;
}

.tabscontainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* z-index: -10; */
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tab-content {
  font-size: 0.75rem;
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
  background-color: white;
  color: #333333;
  border-radius: 0px 0px 4px 4px;
}
