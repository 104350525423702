@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");

body {
  background-color: #16171b;
}
.google {
  background-color: #4080ea;
}
.font-400 {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

.font-700 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
}

.PhoneInputInput {
  background-color: #16171b !important;
  outline: none;
  padding-left: 10px;
}

.scrollbar ::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.largescreen {
  display: none;
}

.PhoneInputCountrySelect {
  background-color: #16171b !important;
}

#root::-webkit-scrollbar {
  display: none;
}


#root {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

@media screen and (min-width: 515px) {
  .largescreen {
    position: fixed;
    background: #000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

