.zindex{
    z-index:2;
  
  }
  .zindexguy{
    z-index:1;
  }
  .zindexminus{
    z-index:-1;
  
  }